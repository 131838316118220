import React from "react";

/**
 *  components
 */
import Main from "./layouts/Main";
import { Login } from "src/components/login/login";
import { Home } from "./components/home/home";

/**
 *  external
 */
import { Routes, Route, Navigate } from "react-router-dom";

const App = () => (
  <Routes>
    <Route exact path="/:channelID" element={<Main />}>
      <Route path="login" element={<Login />} />
      <Route path="home" element={<Home />} />
    </Route>
    <Route exact path="/" element={<Navigate to="/prosperia" />} />
  </Routes>
);

export default App;

//Built-in
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
export const TabPanel = (props) => {
  const { children, value, index, styleBox, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={styleBox}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  styleBox: PropTypes.any,
};

//Built-in
import { useEffect, useState } from "react";
import { iSigns, Referable } from "src/core/models/Eye";
import { iData } from "src/core/models/DiseasesData";
import { Branch } from "src/core/models/Patient";
import { buildDiseases } from "src/core/util/util";

interface iUseDiseases {
  selectedSigns: iSigns | null;
  branch: Branch | undefined;
}

const useDiseases = ({ selectedSigns, branch }: iUseDiseases) => {
  const [displayDiseases, setDisplayDiseases] = useState<iData[] | null>(null);

  const [referable, setReferable] = useState<Referable | null>(null);

  const isAdult = !branch || branch === "retinia-adult";

  useEffect(() => {
    if (selectedSigns) {
      const build = buildDiseases(selectedSigns, isAdult);
      const { data, referable } = build;
      setDisplayDiseases(data);
      if (referable) setReferable("referable");
      else setReferable("non-referable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSigns]);

  return { displayDiseases, referable, setReferable };
};

export default useDiseases;

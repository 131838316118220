//Built-in
import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

//Styles
import "./header.scss";

/**
 *  external
 */
import { useNavigate, useParams } from "react-router-dom";

export const Header = ({ primaryColor, logoHeader }) => {
  const token = localStorage.getItem("token");

  let { channelID } = useParams();

  const navigate = useNavigate();

  const styles = {
    background: primaryColor,
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/${channelID}/login`);
  };

  return (
    <header style={styles} className="wrapper_header">
      {logoHeader && <img src={logoHeader} alt="brand" />}

      {token ? (
        <span onClick={() => handleLogout()} className="btn_logout">
          <ExitToAppIcon />
          <span>Logout</span>
        </span>
      ) : null}
    </header>
  );
};

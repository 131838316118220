import React, { createContext } from "react";

//Models
import {
  iEye,
  EyeStatus,
  Referable,
  iSigns,
  iImage,
} from "src/core/models/Eye";
import { iDisplaySigns, iCheckDataSigns } from "src/core/models/SignsData";
import { iData } from "src/core/models/DiseasesData";

//Hooks
import useEyes from "src/hook/useEyes";
import useImages from "src/hook/useImages";
import useSigns from "src/hook/useSigns";
import useDiseases from "src/hook/useDiseases";
import useCurrentEye from "src/hook/useCurrentEye";
import { Branch } from "src/core/models/Patient";

export interface iPatientEvaluationContext {
  eyes: iEye[] | null;
  selectedSigns: iSigns | null;
  images: iImage[];
  comment: string;
  eyeIndex: number;
  currentEye: iEye | null;
  referable: Referable | null;
  imageIndex: number;
  displaySigns: iDisplaySigns[] | null;
  currentImage: iImage | null;
  status: EyeStatus;
  displayDiseases: iData[] | null;
  setEyeIndex: React.Dispatch<React.SetStateAction<number>>;
  setStatus: React.Dispatch<React.SetStateAction<EyeStatus>>;
  setReferable: React.Dispatch<React.SetStateAction<Referable | null>>;
  setEyes: React.Dispatch<React.SetStateAction<iEye[] | null>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setImageIndex: React.Dispatch<React.SetStateAction<number>>;
  checkDataSigns: (check: iCheckDataSigns) => void;
}

export const PatientEvaluationContext =
  createContext<iPatientEvaluationContext | null>(null);

interface iPatientEvaluationProvider {
  data: iEye[] | null;
  branch: Branch | undefined;
  children: React.ReactNode;
}

const PatientEvaluationProvider = ({
  data,
  branch,
  children,
}: iPatientEvaluationProvider) => {
  const { eyes, eyeIndex, setEyes, setEyeIndex, currentEye } = useEyes({
    data,
  });

  const { status, setStatus, comment, setComment } = useCurrentEye({
    currentEye,
  });

  const { images, imageIndex, currentImage, setImageIndex } = useImages({
    currentEye,
  });

  const { selectedSigns, displaySigns, checkDataSigns } = useSigns({
    currentEye,
    branch,
  });

  const { displayDiseases, referable, setReferable } = useDiseases({
    selectedSigns,
    branch,
  });

  return (
    <PatientEvaluationContext.Provider
      value={{
        eyes,
        selectedSigns,
        images,
        comment,
        eyeIndex,
        currentEye,
        referable,
        imageIndex,
        displaySigns,
        currentImage,
        status,
        displayDiseases,
        setEyeIndex,
        setStatus,
        setReferable,
        setEyes,
        setComment,
        setImageIndex,
        checkDataSigns,
      }}
    >
      {children}
    </PatientEvaluationContext.Provider>
  );
};

export default PatientEvaluationProvider;

//Built-in
import { useState, useEffect } from "react";
import { EyeStatus, iEye, iImage } from "src/core/models/Eye";

interface iUseImages {
  currentEye: iEye | null;
}

const useImages = ({ currentEye }: iUseImages) => {
  const [images, setImages] = useState<iImage[]>([]);

  const [status, setStatus] = useState<EyeStatus>("pending");

  const [imageIndex, setImageIndex] = useState<number>(0);

  const [currentImage, setCurrentImage] = useState<iImage | null>(null);

  useEffect(() => {
    if (currentEye) {
      const { images, status } = currentEye;
      setStatus(status);
      setImages(images);
      setImageIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEye]);

  useEffect(() => {
    setCurrentImage(images[imageIndex]);
  }, [imageIndex, images]);

  return { status, images, imageIndex, currentImage, setImageIndex };
};

export default useImages;

import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CloseIcon from "@mui/icons-material/Close";

/**
 *  external
 */
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

export const FieldTypes = ({
  type,
  name,
  value,
  options,
  placeholder,
  handleFieldChange,
  sx,
}) => {
  /**
   *  keyPress event
   */
  const keyPress = (event, isNumber) => {
    let regular = /[a-zA-Z\s]+/g;
    if (isNumber ? regular.test(event.key) : !regular.test(event.key)) {
      event.preventDefault();
    }
  };

  const style = {
    "& .MuiOutlinedInput-root": {
      background: "#f5f5f5",
      ".MuiOutlinedInput-input": {
        background: "#f5f5f5",
        borderRadius: "5px",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#f5f5f5",
        borderRadius: "5px",
      },
    },
  };

  const numberValid = value !== "" && value !== undefined;

  switch (type) {
    case "text":
      return (
        <TextField
          label={placeholder}
          name={name}
          value={value ? value : ""}
          sx={sx}
          onChange={(event) => {
            const data = event.target.value.trimStart();
            handleFieldChange(name, data);
          }}
        />
      );

    case "number":
      return (
        <TextField
          label={placeholder}
          type="number"
          name={name}
          size="small"
          sx={style}
          value={value ? value.toString() : value}
          onKeyPress={(event) => keyPress(event, true)}
          InputProps={{
            inputProps: { min: 0 },
            ...(numberValid && {
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleFieldChange(name, "")}
                >
                  <CloseIcon />
                </InputAdornment>
              ),
            }),
          }}
          onChange={(event) => {
            let value = event.target.value.trimStart();
            if (value) value = +value;
            handleFieldChange(name, value);
          }}
        />
      );

    case "selection":
      return (
        <FormControl sx={style}>
          <Select
            size="small"
            name={name}
            value={value}
            displayEmpty
            onChange={(event) => handleFieldChange(name, event.target.value)}
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            {options.map((option, i) => (
              <MenuItem value={option.value} key={i}>
                {option.component}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case "date":
      return (
        <LocalizationProvider
          utils={DateFnsUtils}
          locale={esLocale}
          dateAdapter={AdapterDateFns}
        >
          <DesktopDatePicker
            disableFuture={name === "follow_up_date" ? false : true}
            inputFormat="dd/MM/yyyy"
            renderInput={(props) => (
              <TextField {...props} size="small" sx={style} />
            )}
            value={value ? value : null}
            InputProps={{
              ...(value && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleFieldChange(name, "")}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }),
            }}
            onChange={(value) => {
              handleFieldChange(name, value ? value : "");
            }}
          />
        </LocalizationProvider>
      );

    default:
      return null;
  }
};

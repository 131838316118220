/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// third party libraries //
import axios from 'axios';

const channelParametersApi = async (channelID) => {
  const url = process.env.REACT_APP_API_GET_CHANNEL_PARAMETERS;
  return axios.get(url, {
    params: { channel: channelID },
  });
};

export default channelParametersApi;


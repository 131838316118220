import React, { useContext, useState, useEffect } from "react";

import {
  PatientFollowContext,
  iPatientFollowContext,
} from "src/context/PatientFollowContext";

//Built-in
import { EyeSide, iEye, iImage, iSigns, Referable } from "src/core/models/Eye";
import { Patient } from "src/core/models/Patient";
import { PocData } from "src/core/models/PocData";

//External
import axios from "axios";
import { iData } from "src/core/models/DiseasesData";
import { mapCatalogDiseases } from "src/core/util/util";

interface iEvaluation {
  displayDiseases: iData[] | null;
  selectedSigns: iSigns | null;
  pacientData: Patient;
  sucData: PocData;
  images: iImage[];
  comment: string;
  eyeIndex: number;
  currentEye: iEye | null;
  referable: Referable | null;
  eyes: iEye[] | null;
  setEyes: React.Dispatch<React.SetStateAction<iEye[] | null>>;
  onClickEyeSideButton: (index: number) => void;
  setShowImage: React.Dispatch<React.SetStateAction<boolean>>;
}

interface iInteraction {
  id: string;
  poc: number;
}

interface iPatientData {
  id: string;
  age: number;
  eyeSide: EyeSide;
}

interface iEvaluationData {
  referable: Referable;
  doctorId: number;
  comment: string;
  completed: boolean;
}

interface iPayload {
  interaction: iInteraction;
  patient: iPatientData;
  images: iImage[];
  evaluation: iEvaluationData;
  dataSubSigns: iSigns;
  diseases: string []
}

const useEvaluation = ({
  displayDiseases,
  selectedSigns,
  pacientData,
  sucData,
  images,
  comment,
  eyeIndex,
  currentEye,
  referable,
  eyes,
  setEyes,
  onClickEyeSideButton,
  setShowImage,
}: iEvaluation) => {
  const context = useContext(PatientFollowContext);

  const { data } = context as iPatientFollowContext;

  const [isFollow, setFollow] = useState<boolean>(false);

  const [isLoader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (isFollow && data && data.id) {
      const { interaction_id, follow_up_code, doctor_id } = pacientData;
      if (!follow_up_code || follow_up_code !== data.id) {
        const postFollow = async () => {
          const payload = {
            interaction_id: interaction_id,
            follow: data?.id,
            doctor_id: doctor_id,
          };
          const url = process.env.REACT_APP_API_POST_FOLLOW_UP as string;
          await axios
            .post(
              url,
              payload
            )
            .then((result: any) => {
              const { date, text } = result["data"]["output"];
              pacientData.follow_up_date = date;
              pacientData.follow_up_code = data?.id;
              pacientData.follow_up_text = text;
              setFollow(false);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        postFollow();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFollow]);

  const handlerEvaluation = (isFinish: boolean): void => {
    const { eyeSide, status } = currentEye as iEye;

    if (status === "evaluated" && !isFinish) {
      nextStep();
      return;
    }

    setLoader(true);
    const token: string = localStorage.getItem("token") as string;
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    const { interaction_id, poc_id, pacient_id, age, doctor_id, branch } =
      pacientData;
    let diseases: string[] = [];
    if (displayDiseases && displayDiseases.length > 0) {
      const filterDiseases = displayDiseases.filter(data => data.catalogueId !== '');
      if (filterDiseases.length > 0) {
        diseases= filterDiseases.map(data => data.catalogueId);
      }              
    }
    const payload: iPayload = {
      interaction: { id: interaction_id, poc: poc_id },
      patient: {
        id: pacient_id,
        age: age,
        eyeSide: eyeSide,
      },
      images: images,
      evaluation: {
        referable: referable as Referable,
        doctorId: doctor_id,
        comment: comment,
        completed: isFinish,
      },
      dataSubSigns: selectedSigns as iSigns,
      diseases: diseases
    };
    const url = process.env.REACT_APP_API_POST_SIGNS_SAVE as string
    axios
      .post(url, payload)
      .then(() => {
        const isRop = !branch || branch === "retinia-rop";
        if (isRop) setFollow(true);
        eyeResult(payload, isFinish);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const eyeResult = (data: iPayload, isFinish: boolean) => {
    const { evaluation, patient, images, dataSubSigns } = data;
    const { comment, referable } = evaluation;
    const { eyeSide } = patient;
    const eye: iEye = {
      comment: comment,
      evaluation: true,
      eyeSide: eyeSide,
      images: images,
      status: "evaluated",
      referable: referable,
      selectedSubSigns: dataSubSigns,
      diseases: mapCatalogDiseases(displayDiseases),
    };
    let eyesData = [...(eyes as iEye[])];
    eyesData[eyeIndex] = eye;
    setEyes(eyesData);
    // finish is false because there are more than 1 eyes
    if (isFinish) {
      let data: string[] = [];
      eyesData.forEach((eye) => {
        const { diseases } = eye;
        data.push(...diseases);
      });
      const set = new Set(data);
      const catalogue = Array.from(set);
      pacientData.diseases = catalogue.join(", ");

      pacientData.status = "evaluated";

      const findReferable = eyesData.filter(
        (eye) => eye.referable === "referable"
      );
      if (findReferable.length > 0) pacientData.referable = "referable";
      else pacientData.referable = "non-referable";
      updateSudData();
      setShowImage(false);
    } else {
      nextStep();
    }
  };

  const nextStep = (): void => {
    if (eyeIndex > 0) onClickEyeSideButton(0);
    else onClickEyeSideButton(1);
  };

  const updateSudData = () => {
    const percentage =
      ((sucData.total - (sucData.total_pending - 1)) * 100) / sucData.total;
    sucData.percentage = +percentage.toFixed(1);
    sucData.total_pending = sucData.total_pending - 1;
  };

  return { handlerEvaluation, isLoader };
};

export default useEvaluation;

import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

//Styles
import "./barProgress.scss";

const LinearProgressWithLabel = ({
  total,
  ref_total,
  ref_percentage,
  bar_height,
}) => {
  const styleBar = {
    height: bar_height || 40,
  };

  return (
    <Box display="flex" alignItems="center">
      <Box width="80%" mr={1}>
        <LinearProgress
          style={styleBar}
          color="primary"
          variant="determinate"
          value={ref_percentage}
        />
      </Box>
      <Box minWidth={130}>
        <p className="mg m0 bar-text">Total: {total}</p>
        <p className="mg m0 bar-text">
          Ref: {ref_total} ({ref_percentage}%)
        </p>
      </Box>
    </Box>
  );
};

export default function LinearWithValueLabel({
  total,
  ref_total,
  ref_percentage,
  bar_height,
}) {
  return (
    <div className="mg b3">
      <LinearProgressWithLabel
        total={total}
        ref_total={ref_total}
        ref_percentage={ref_percentage}
        bar_height={bar_height}
      />
    </div>
  );
}

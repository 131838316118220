import { iSigns } from "./Eye";
import { Signs } from "./Signs";
import { iDisplaySigns, iSubSigns } from "./SignsData";
import { buildSigns } from "../util/util";

export class SignsRop extends Signs {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  buildInitSigns = (): iSigns => {
    const data: iSigns = {
      "S-ROP": { "0": false, "1": false, "2": false, "3": false, "4": false },
      "S-VA": { "8": false, "9a": false, "9b": false, X: false },
      "S-EX": { "1": false },
      "S-HM": {
        "2": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
      },
      "S-OT": {
        XR: false,
        XG: false,
        "16": false,
      },
    };
    return data;
  };

  buildDisplaySigns = (): iDisplaySigns[] => {
    return [
      this.buildSROP(),
      this.buildSVA(),
      this.buildSEX(),
      this.buildSHM(),
      this.buildSOT(),
    ];
  };

  buildSROP = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "0": {
          label: "Retina inmadura",
          value: false,
        },
      },
      {
        "1": {
          label: "Línea de demarcación",
          value: false,
        },
      },
      {
        "2": {
          label: "Borde elevado",
          value: false,
        },
      },
      {
        "3": {
          label: "Neovascularización extra-retiniana",
          value: false,
        },
      },
      {
        "4": {
          label: "Desprendimiento de retina traccional",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Signos de ROP",
      code: "S-ROP",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSVA = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "8": {
          label: "Dilatación venosa",
          value: false,
        },
      },
      {
        "9a": {
          label: "Tortuosidad vascular leve/moderada (preplus)",
          value: false,
        },
      },
      {
        "9b": {
          label: "Tortuosidad vascular alta (plus)",
          value: false,
        },
      },
      {
        X: {
          label: "Otras anomalías vasculares",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Anomalías vasculares",
      code: "S-VA",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSEX = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Exudados duros",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Exudados",
      code: "S-EX",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSHM = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "2": {
          label: "Hemorragia vítrea",
          value: false,
        },
      },
      {
        "3": {
          label: "Hemorragias pre-retinianas",
          value: false,
        },
      },
      {
        "4": {
          label: "Hemorragias superficiales",
          value: false,
        },
      },
      {
        "5": {
          label: "Hemorragias intra-retinianas",
          value: false,
        },
      },
      {
        "6": {
          label: "Hemorragia sub-retinianas",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Hemorragias",
      code: "S-HM",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSOT = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        XR: {
          label: "Otros retina",
          value: false,
        },
      },
      {
        XG: {
          label: "Otros general",
          value: false,
        },
      },
      {
        "16": {
          label: "Arteria hialoidea persistente",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Otros",
      code: "S-OT",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };
}

//Built-in
import React from "react";
import Button from "@mui/material/Button";

const Buttons = (props) => {
  return (
    <Button
      disabled={props.disabled}
      type={props.type}
      sx={{color: 'white', background: props.colors }}
      variant="contained"
    >
      {props.title}
    </Button>
  );
};

export default Buttons;

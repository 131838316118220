import { iSigns } from "./Eye";
import { Signs } from "./Signs";
import { iDisplaySigns, iSubSigns } from "./SignsData";
import { buildSigns } from "../util/util";

export class SignsAdult extends Signs {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  buildInitSigns = (): iSigns => {
    const data: iSigns = {
      "S-AT": { "1": false, "2": false, "3a": false, "3b": false },
      "S-DA": { "1": false, "2": false, "3": false, "4": false, X: false },
      "S-DR": {
        "1a": false,
        "1b": false,
        "3": false,
        "2b": false,
        "2a": false,
      },
      "S-EX": { "2": false, "1a": false, "1b": false, "1c": false },
      "S-HM": {
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "6": false,
        "5a": false,
        "5b": false,
      },
      "S-OT": {
        "1": false,
        "2": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "17": false,
        XR: false,
        XG: false,
      },
      "S-NP": {
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        X: false,
      },
      "S-Q": { "1": false, "2": false, "3": false },
      "S-VA": {
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        X: false,
      },
    };
    return data;
  };

  buildDisplaySigns = (): iDisplaySigns[] => {
    return [
      this.buildSVA(),
      this.buildSTA(),
      this.buildSDR(),
      this.buildSEX(),
      this.buildSHM(),
      this.buildSOT(),
      this.buildSNP(),
      this.buildSDA(),
      this.buildSQ(),
    ];
  };

  buildSVA = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Anomalías microvasculares intra-retinianas",
          value: false,
        },
      },
      {
        "2": {
          label: "Dilataciones venosas arrosariadas",
          value: false,
        },
      },
      {
        "3": {
          label: "Neovascularización",
          value: false,
        },
      },
      {
        "4": {
          label: "Cambios en el reflejo arterial",
          value: false,
        },
      },
      {
        "5": {
          label: "Cruces arterio-venosos",
          value: false,
        },
      },
      {
        "6": {
          label: "Estrechamiento arterial focalizado",
          value: false,
        },
      },
      {
        "7": {
          label: "Estrechamiento arterial generalizado",
          value: false,
        },
      },
      {
        X: {
          label: "Otras anomalías vasculares",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Anomalías Vasculares",
      code: "S-VA",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSTA = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Corio-retiniana difusa",
          value: false,
        },
      },
      {
        "2": {
          label: "Corio-retiniana en parches",
          value: false,
        },
      },
      {
        "3a": {
          label: "Macular, involucra la fovea",
          value: false,
        },
      },
      {
        "3b": {
          label: "Macular, no involucra la fovea",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Atrofia",
      code: "S-AT",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSDR = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1a": {
          label: "Pequeñas, <20",
          value: false,
        },
      },
      {
        "1b": {
          label: "Pequeñas, 20+",
          value: false,
        },
      },
      {
        "2a": {
          label: "Medianas, <20",
          value: false,
        },
      },
      {
        "2b": {
          label: "Medianas, 20+",
          value: false,
        },
      },
      {
        "3": {
          label: "Grandes",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Drusas",
      code: "S-DR",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSEX = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1a": {
          label: "Duros a menos de 1 DD de la fovea",
          value: false,
        },
      },
      {
        "1b": {
          label:
            "Duros a más de 1 DD de la fovea y dentro de las arcadas temporales",
          value: false,
        },
      },
      {
        "1c": {
          label: "Duros fuera de las arcadas temporales",
          value: false,
        },
      },
      {
        "2": {
          label: "Algodonosos",
          value: false,
        },
      },
    ];

    const data: iDisplaySigns = {
      label: "Exudados",
      code: "S-EX",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSHM = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Microaneurismas",
          value: false,
        },
      },
      {
        "2": {
          label: "Hemorragia vítrea",
          value: false,
        },
      },
      {
        "3": {
          label: "Hemorragias pre-retinianas",
          value: false,
        },
      },
      {
        "4": {
          label: "Hemorragias superficiales",
          value: false,
        },
      },
      {
        "5a": {
          label: "Hemorragias intra-retinianas, <20",
          value: false,
        },
      },
      {
        "5b": {
          label: "Hemorragias intra-retinianas, 20+",
          value: false,
        },
      },
      {
        "6": {
          label: "Hemorragia sub-retinianas",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Hemorragias o Microaneurismas",
      code: "S-HM",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSOT = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Cambios en el epitelio pigmentario",
          value: false,
        },
      },
      {
        "2": {
          label: "Membrana neovascular",
          value: false,
        },
      },
      {
        "4": {
          label: "Estrías de laca",
          value: false,
        },
      },
      {
        "5": {
          label: "Mancha de Fuchs",
          value: false,
        },
      },
      {
        "6": {
          label: "Cicatriz disciforme",
          value: false,
        },
      },
      {
        "7": {
          label: "Membrana epirretiniana",
          value: false,
        },
      },
      {
        "8": {
          label: "Agujero macular",
          value: false,
        },
      },
      {
        "9": {
          label: "Desprendimiento regmatógeno",
          value: false,
        },
      },
      {
        "10": {
          label: "Desprendimiento traccional",
          value: false,
        },
      },
      {
        "11": {
          label: "Oclusión arterial (de rama o central)",
          value: false,
        },
      },
      {
        "12": {
          label: "Oclusión venosa (de rama o central)",
          value: false,
        },
      },
      {
        "17": {
          label: "Hialosis asteroidea",
          value: false,
        },
      },
      {
        XR: {
          label: "Otros retina",
          value: false,
        },
      },
      {
        XG: {
          label: "Otros general",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Otros hallazgos patológicos",
      code: "S-OT",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSNP = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Fondo coroideo",
          value: false,
        },
      },
      {
        "2": {
          label: "Mielinización de fibras nerviosas",
          value: false,
        },
      },
      {
        "3": {
          label: "Nevo coroideo",
          value: false,
        },
      },
      {
        "4": {
          label: "Signos de foto-coagulación",
          value: false,
        },
      },
      {
        X: {
          label: "Otros hallazgos no patológicos",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Otros hallazgos no patológicos",
      code: "S-NP",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSDA = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Excavación >= 6/10",
          value: false,
        },
      },
      {
        "2": {
          label: "Incumplimiento de Regla I>S>N>T",
          value: false,
        },
      },
      {
        "3": {
          label: "Hemorragias alrededor del nervio óptico",
          value: false,
        },
      },
      {
        "4": {
          label: "Edema de disco",
          value: false,
        },
      },
      {
        X: {
          label: "Otros disco óptico",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Anomalías de disco óptico",
      code: "S-DA",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };

  buildSQ = (): iDisplaySigns => {
    const display: iSubSigns[] = [
      {
        "1": {
          label: "Mácula",
          value: false,
        },
      },
      {
        "2": {
          label: "Vasos sanguíneos",
          value: false,
        },
      },
      {
        "3": {
          label: "Disco",
          value: false,
        },
      },
    ];
    const data: iDisplaySigns = {
      label: "Elementos sin calidad suficiente",
      code: "S-Q",
      subSigns: buildSigns(display),
      display: display,
    };
    return data;
  };
}

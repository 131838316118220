//Built-in
import React, { useContext, memo } from "react";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

//Assets
import openEye from "src/assets/icons/open_eye.svg";
import closedEye from "src/assets/icons/closed_eye.svg";

const EyeSideButton = ({
  index,
  isActive,
  onClick,
  status,
  referable,
  eyeSide,
}) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor } = channelParameters;
  const secondary = secondaryColor || primaryColor;

  const icon = isActive ? openEye : closedEye;

  const side = eyeSide === "left eye" ? "Izquierdo" : "Derecho";

  const borderColor = isActive ? secondary : `transparent`;

  const useStyles = makeStyles(() => ({
    root: {
      background: "#fff",
      width: "95px",
      height: "95px",
      minWidth: "80px !important",
      borderRadius: "12px !important",
      margin: "5px 0",
      padding: "4px !important",
      border: `solid 4px ${borderColor}`,
      display: "flex",
      flexDirection: "column",
      "&:hover": {
        backgroundColor: "#e0e0e0",
      },
    },
    eyeContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    typography: {
      fontWeight: "bold",
      color: "#B2B2B2",
      fontSize: "0.80rem",
    },
    icoCheck: {
      position: "absolute",
      bottom: "-13px",
      right: "-12px",
      background: "#ffffff",
      borderRadius: "50%",
      boxShadow: "0 0 9px 3px rgba(0, 0, 0, 0.1)",
      height: "24px",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.eyeContainer}>
      <Button
        variant="contained"
        className={classes.root}
        onClick={(event) => onClick(index)}
      >
        <img
          alt="eyeImage"
          src={icon}
          style={{ width: "55%", margin: "5px 0", fill: "white" }}
        />
        <span className={classes.icoCheck}>
          {status !== "evaluated" ? (
            <RemoveCircleOutlineIcon color="disabled" />
          ) : (
            <CheckCircleIcon style={{ color: green[500] }} />
          )}
        </span>
        <Typography variant="caption" className={classes.typography}>
          {side}
        </Typography>
      </Button>
      {
        <h5 className="mg b0 t1">
          {referable
            ? referable === "referable"
              ? "Referible"
              : "No referible"
            : "No referible"}
        </h5>
      }
    </div>
  );
};

export default memo(EyeSideButton);

import { useEffect, useContext } from "react";

/**
 * components
 */
import { Header } from "src/components/header/header";
import { Footer } from "src/components/footer/footer";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

/**
 * external
 */
import { Outlet, useNavigate } from "react-router-dom";

export const Layouts = () => {
  let navigate = useNavigate();

  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, logoHeader } = channelParameters;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate(`home`);
    else navigate(`login`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header primaryColor={primaryColor} logoHeader={logoHeader} />
      <Outlet />
      <Footer primaryColor={primaryColor} logoHeader={logoHeader} />
    </>
  );
};

/**
 * mui
 */
import { red } from "@mui/material/colors";

/**
 * models
 */
import { Diseases } from "./Diseases";
import { iData, iConcatSigns, iReport, iDiseasesCode } from "./DiseasesData";

export class DiseasesAdult extends Diseases {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  buildDiseases = (signs: iConcatSigns): iReport => {
    let diseases: iData[] = [];
    let referable = false;
    //DR//
    const valueDR = this.DR_level(signs);
    if (valueDR !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-DR", valueDR));
    //LMDR
    const valueLMDR = this.LMDR_level(signs);
    if (!referable && valueLMDR !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-LMDR", valueLMDR));
    //AMD
    const valueAMD = this.AMD_level(signs);
    if (!referable && valueAMD !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-AMD", valueAMD));
    //D-ME
    const valueME = this.ME_level(signs);
    if (!referable && valueME !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-ME", valueME));
    //D-HR
    const valueHR = this.HR_level(signs);
    if (!referable && valueHR !== "0" && valueHR !== "1") referable = true;
    diseases.push(this.buildDisplayDiseases("D-HR", valueHR));
    //D-PM
    const valuePM = this.PM_level(signs);
    if (!referable && valuePM !== "0" && valuePM !== "1") referable = true;
    diseases.push(this.buildDisplayDiseases("D-PM", valuePM));
    //D-GS
    const valueGS = this.GS_presence(signs);
    if (!referable && valueGS !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-GS", valueGS));
    //D-OR
    const valueOR = this.OR_presence(signs);
    if (!referable && valueOR !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-OR", valueOR));
    //D-OG
    const valueOG = this.OG_presence(signs);
    if (!referable && valueOG !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-OG", valueOG));

    //N-PF
    const valueNPF = this.NPF_presence(signs);
    diseases.push(this.buildDisplayDiseases("D-NPF", valueNPF));

    let data = diseases.filter(
      (data) => data.value !== "Sin" && data.value !== "No"
    );
    data = data.length > 0 ? data : [{ label: "Sin", color: red[500], catalogueId: '' }];
    return {
      data: data,
      referable: referable,
    };
  };

  buildDiseasesCode = (): iDiseasesCode => {
    const diseasesCode: iDiseasesCode = {
      "D-DR": {
        name: "Retinopatía Diabética",
        classification: {
          "0": "Sin",
          "1": "Leve",
          "2": "Moderada",
          "3": "Severa",
          "4": "Proliferativa",
        },
        catalogue: {
          "1": "Retinopatía Diabética Leve",
          "2": "Retinopatía Diabética Moderada",
          "3": "Retinopatía Diabética Severa",
          "4": "Retinopatía Diabética Proliferativa",
        },
      },

      "D-LMDR": {
        name: "Retinopatía Diabética Modificada por Laser",
        classification: { "0": "Sin", "1": "Inactiva", "2": "Activa" },
        catalogue: {
          "1": "Retinopatía Diabética Modificada por Laser Inactiva",
          "2": "Retinopatía Diabética Modificada por Laser Activa",
        },
      },

      "D-AMD": {
        name: "Degeneración Macular Relacionada con la Edad",
        classification: {
          "0": "Sin",
          "1": "Leve",
          "2": "Intermedia",
          "3": "Avanzada",
        },
        catalogue: {
          "1": "Degeneración Macular Relacionada con la Edad Leve",
          "2": "Degeneración Macular Relacionada con la Edad Intermedia",
          "3": "Degeneración Macular Relacionada con la Edad Avanzada",
        },
      },

      "D-ME": {
        name: "Edema Macular",
        classification: { "0": "Sin", "1": "No Central", "2": "Central" },
        catalogue: {
          "1": "Edema Macular No Central",
          "2": "Edema Macular Central",
        },
      },

      "D-HR": {
        name: "Retinopatía Hipertensiva",
        classification: {
          "0": "Sin",
          "1": "Leve",
          "2": "Moderada",
          "3": "Maligna",
        },
        catalogue: {
          "1": "Retinopatía Hipertensiva Leve",
          "2": "Retinopatía Hipertensiva Moderada",
          "3": "Retinopatía Hipertensiva Maligna",
        },
      },

      "D-PM": {
        name: "Miopía patológica",
        classification: {
          "0": "Sin",
          "1": "1",
          "2": "2",
          "3": "3",
          "4": "4",
          "5": "PLUS",
        },
        catalogue: {
          "1": "Miopía Patológica 1",
          "2": "Miopía Patológica 2",
          "3": "Miopía Patológica 3",
          "4": "Miopía Patológica 4",
          "5": "Miopía Patológica PLUS",
        },
      },

      "D-GS": {
        name: "Sospecha de glaucoma",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Sospecha de Glaucoma",
        },
      },
      "D-OR": {
        name: "Otra retina",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Otra Retina",
        },
      },
      "D-OG": {
        name: "Otra general",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Otra general",
        },
      },
      "D-NPF": {
        name: "Hallazgos no patológicos",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Hallazgos no patológicos",
        },
      },
    };

    return diseasesCode;
  };

  DR_level = (signs: iConcatSigns): string => {
    if (signs["S-VA-3"] || signs["S-HM-2"] || signs["S-HM-3"]) return "4";

    if (
      signs["S-OT-12"] === false ||
      signs["S-HM-1"] ||
      signs["S-EX-1a"] ||
      signs["S-EX-1b"] ||
      signs["S-EX-1c"]
    ) {
      // Severe
      if (signs["S-HM-5b"]) return "3";

      // Severe with MA or less than 20 hemorrhages
      if (
        (signs["S-HM-1"] || signs["S-HM-5a"]) &&
        (signs["S-VA-1"] || signs["S-VA-2"])
      )
        return "3";

      // Moderate (MA not necessary)
      if (signs["S-HM-5a"]) return "2";
    }

    if (signs["S-HM-1"]) {
      // Moderate (MA necessary)
      if (
        signs["S-EX-1a"] ||
        signs["S-EX-1b"] ||
        signs["S-EX-1c"] ||
        signs["S-EX-2"]
      )
        return "2";

      // Mild
      return "1";
    }
    return "0";
  };

  LMDR_level = (signs: iConcatSigns): string => {
    if (signs["S-NP-4"]) {
      //Active
      if (
        signs["S-VA-3"] ||
        signs["S-EX-1a"] ||
        signs["S-EX-1b"] ||
        signs["S-HM-2"] ||
        signs["S-HM-3"]
      )
        return "2";

      //# Inactive
      return "1";
    }

    return "0";
  };

  AMD_level = (signs: iConcatSigns): string => {
    //# Advanced
    if (
      (signs["S-DR-1a"] ||
        signs["S-DR-1b"] ||
        signs["S-DR-2a"] ||
        signs["S-DR-2b"] ||
        signs["S-DR-3"] ||
        signs["S-OT-1"]) &&
      (signs["S-AT-3a"] || signs["S-OT-2"])
    )
      return "3";

    //# Intermidiate
    if (
      (signs["S-AT-3b"] &&
        (signs["S-DR-1a"] ||
          signs["S-DR-1b"] ||
          signs["S-DR-2a"] ||
          signs["S-OT-1"])) ||
      signs["S-DR-2b"] ||
      signs["S-DR-3"]
    )
      return "2";

    //# Mild
    if (signs["S-DR-1b"] || signs["S-DR-2a"] || signs["S-OT-1"]) return "1";

    return "0";
  };

  ME_level = (signs: iConcatSigns): string => {
    // Central
    if (signs["S-EX-1a"]) return "2";

    //# 0n-central
    if (signs["S-EX-1b"]) return "1";

    return "0";
  };

  HR_level = (signs: iConcatSigns): string => {
    if (
      signs["S-VA-4"] ||
      signs["S-VA-5"] ||
      signs["S-VA-6"] ||
      signs["S-VA-7"]
    ) {
      if (
        signs["S-HM-1"] ||
        signs["S-HM-4"] ||
        signs["S-HM-5a"] ||
        signs["S-HM-5b"] ||
        signs["S-EX-1a"] ||
        signs["S-EX-1b"] ||
        signs["S-EX-1c"] ||
        signs["S-EX-2"]
      ) {
        //# Malignant
        if (signs["S-DA-4"]) return "3";

        //# Moderate
        return "2";
      }
      //# Mild
      return "1";
    }
    return "0";
  };

  PM_level = (signs: iConcatSigns): string => {
    if (signs["S-NP-1"] || signs["S-AT-1"] || signs["S-AT-2"]) {
      if (signs["S-OT-2"] || signs["S-OT-4"] || signs["S-OT-5"]) return "5";

      if (signs["S-AT-3a"] || signs["S-AT-3b"]) return "4";

      if (signs["S-AT-2"]) return "3";

      if (signs["S-AT-1"]) return "2";

      if (signs["S-NP-1"]) return "1";
    }
    return "0";
  };

  GS_presence = (signs: iConcatSigns): string => {
    if (signs["S-DA-1"] || signs["S-DA-2"] || signs["S-DA-3"]) return "1";

    return "0";
  };

  OR_presence = (signs: iConcatSigns): string => {
    if (
      signs["S-HM-6"] ||
      signs["S-OT-6"] ||
      signs["S-OT-7"] ||
      signs["S-OT-8"] ||
      signs["S-OT-9"] ||
      signs["S-OT-10"] ||
      signs["S-OT-11"] ||
      signs["S-OT-12"] ||
      signs["S-OT-XR"]
    )
      return "1";

    if (
      (signs["S-AT-3a"] || signs["S-AT-3b"] || signs["S-OT-2"]) &&
      this.AMD_level(signs) === "0" &&
      this.PM_level(signs) === "0"
    )
      return "1";

    if (
      (signs["S-OT-4"] || signs["S-OT-5"] || signs["S-AT-2"]) &&
      this.PM_level(signs) === "0"
    )
      return "1";

    if (
      (signs["S-EX-1c"] || signs["S-EX-2"]) &&
      this.DR_level(signs) === "0" &&
      this.HR_level(signs) === "0" &&
      this.ME_level(signs) === "0"
    )
      return "1";

    if (signs["S-HM-4"] && this.HR_level(signs) === "0") return "1";

    return "0";
  };

  OG_presence = (signs: iConcatSigns): string => {
    if (signs["S-OT-XG"] || signs["S-DA-X"] || signs["S-OT-17"]) return "1";

    if (signs["S-DA-4"] && this.HR_level(signs) !== "3") return "1";

    if (signs["S-VA-1"] && this.DR_level(signs) === "0") return "1";

    return "0";
  };

  NPF_presence = (signs: iConcatSigns): string => {
    if (signs["S-NP-2"] || signs["S-NP-3"] || signs["S-NP-X"]) return "1";

    return "0";
  };
}

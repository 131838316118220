//Built-in
import { useState, useEffect } from "react";
import { EyeStatus, iEye } from "src/core/models/Eye";

interface iUseCurrentEye {
  currentEye: iEye | null;
}

const useCurrentEye = ({ currentEye }: iUseCurrentEye) => {
  const [status, setStatus] = useState<EyeStatus>("pending");

  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    if (currentEye) {
      const { comment, status } = currentEye;
      setStatus(status);
      setComment(comment);
    }
  }, [currentEye]);

  return { status, setStatus, comment, setComment };
};

export default useCurrentEye;

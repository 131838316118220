import React, { useState } from "react";

/**
 * mui
 */
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { green, red } from "@mui/material/colors";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";

/**
 * components
 */
import { FieldTypes } from "src/components/fieldTypes/FieldTypes";

/**
 * external
 */
import moment from "moment";

function descendingComparator(a, b, orderBy, isAsc) {
  let aVal = a[orderBy];
  let bVal = b[orderBy];

  if (aVal && bVal) {
    if (orderBy === "date" || orderBy === "follow_up_date") {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  } else if (isAsc) {
    if (!aVal) return 1;
    else return -1;
  } else {
    if (!aVal) return -1;
    else return 1;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, true)
    : (a, b) => -descendingComparator(a, b, orderBy, false);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({
  style,
  classes,
  order,
  orderBy,
  onRequestSort,
  handleFieldChange,
  headCells,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={style}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={headCell.sx}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "diseases" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ height: "50px" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Typography className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Typography>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography sx={{ color: "#ffffff", fontWeight: 500 }}>
                {headCell.label}
              </Typography>
            )}
            {headCell.field && (
              <FieldTypes
                type={headCell.field.type}
                name={headCell.field.name}
                value={headCell.field.value}
                options={headCell.field.options}
                handleFieldChange={handleFieldChange}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    fontFamily: "Lobster",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const PatientList = ({
  isAdult,
  bgColor,
  rows,
  setShowImage,
  setPacientData,
  page,
  setPage,
  handleFieldChange,
  headCells,
  order,
  orderBy,
  handleRequestSort,
}) => {
  const classes = useStyles();

  const [selected, setSelected] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const stylesTable = {
    background: bgColor,
  };

  return (
    <Box className={`${classes.root} wrapper_table_list`}>
      <TableContainer className="wrapper_table" sx={{ overflowX: "scroll" }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            style={stylesTable}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            handleFieldChange={handleFieldChange}
            headCells={headCells}
          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name + index}
                    selected={isItemSelected}
                  >
                    <TableCell align="left" component="th" scope="row">
                      <Box
                        className="box_name_th cursor-pointer"
                        onClick={() => {
                          setPacientData(row);
                          setShowImage(true);
                        }}
                      >
                        <Typography className="mg r1">
                          <AccountCircleIcon />
                        </Typography>
                        <Typography variant="subtitle2" className="link_user">
                          {row.name} {row.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        className="box_name_th cursor-pointer"
                        onClick={() => {
                          setPacientData(row);
                          setShowImage(true);
                        }}
                      >
                        {row.pacient_id}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {row.enhancedAge} {row?.time}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.date).utc().format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="center">
                      {row.referable === "referable"
                        ? "Referible"
                        : "No referible"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.diseases && (
                        <Typography
                          variant="body2"
                          sx={{ color: red[500], fontWeight: 500 }}
                        >
                          {row.diseases}
                        </Typography>
                      )}
                    </TableCell>
                    {!isAdult && (
                      <TableCell align="center">
                        {row?.follow_up_date
                          ? moment(row?.follow_up_date)
                              .utc()
                              .format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                    )}

                    <TableCell align="center">
                      {row.quality_images ? (
                        <CheckIcon style={{ color: green[500] }} />
                      ) : (
                        <CloseIcon style={{ color: red[500] }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <>
                        {!row.status ? (
                          <Tooltip title="No se capturaron imágenes">
                            <SignalCellularNoSimIcon color="disabled" />
                          </Tooltip>
                        ) : row.status === "pending" ? (
                          <Tooltip title="No etiquetado">
                            <RemoveCircleOutlineIcon color="disabled" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Etiquetado">
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </Tooltip>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PatientList;

//Built-in
import React, { createRef, memo, useCallback, useContext } from "react";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

//Context
import {
  PatientEvaluationContext,
  iPatientEvaluationContext,
} from "src/context/PatientEvaluationContext";

//Components
import EvaluationStep from "./evaluationStep/evaluationStep";
import ThumbnailImage from "./thumbnailImage/thumbnailImage";
import ImageMagnifier from "./imageMagnifier/imageMagnifier";
import EyeSideButton from "./eyeSideButton/eyeSideButton";
import SubSigns from "./subSigns/subSigns";
import Diseases from "./diseases/diseases";

//Hooks
import useEvaluation from "src/hook/useEvaluation";

//Models
import { Patient } from "src/core/models/Patient";
import { PocData } from "src/core/models/PocData";
import { iEye } from "src/core/models/Eye";

interface iPatientEvaluation {
  pacientData: Patient;
  sucData: PocData;
  setShowImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const PatientEvaluation = ({
  pacientData,
  sucData,
  setShowImage,
}: iPatientEvaluation) => {
  const navRef = createRef<HTMLDivElement>();

  const context = useContext(PatientEvaluationContext);

  const {
    eyes,
    selectedSigns,
    images,
    comment,
    eyeIndex,
    currentEye,
    referable,
    imageIndex,
    displaySigns,
    currentImage,
    status,
    displayDiseases,
    setEyeIndex,
    setStatus,
    setReferable,
    setEyes,
    setComment,
    setImageIndex,
    checkDataSigns,
  } = context as iPatientEvaluationContext;

  const { branch } = pacientData;

  const onClickEyeSideButton = useCallback(
    (index: number): void => {
      const eyeList = eyes as iEye[];
      updateEye(eyeList[index]);
      setEyeIndex(index);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEyeIndex, eyes]
  );

  const updateEye = (eye: iEye) => {
    const { referable, status } = eye;
    setStatus(status);
    setReferable(referable ? referable : "non-referable");
  };

  const { handlerEvaluation, isLoader } = useEvaluation({
    displayDiseases,
    selectedSigns,
    pacientData,
    sucData,
    images,
    comment,
    eyeIndex,
    currentEye,
    referable,
    eyes,
    setEyes,
    onClickEyeSideButton,
    setShowImage
  });

  const onChangeComment = useCallback(
    (comment: string): void => setComment(comment),
    [setComment]
  );

  const onClickThumbnailImage = useCallback(
    (index: number): void => setImageIndex(index),
    [setImageIndex]
  );

  const handleNav = (direction: "left" | "right") => {
    if (navRef.current) {
      if (direction === "left") navRef.current.scrollLeft -= 250;
      else navRef.current.scrollLeft += 250;
    }
  };

  const onClickEvaluation = (e: React.MouseEvent, isFinish: boolean) => {
    handlerEvaluation(isFinish);
  };

  return images && images.length > 0 ? (
    <>
      <div className="content_user_details">
        <Box style={{ display: "flex" }}>
          <div className="content_eyes">
            {eyes?.map((eye, index) => {
              if (index === eyeIndex)
                return (
                  <EyeSideButton
                    key={index}
                    index={index}
                    isActive={true}
                    status={status}
                    referable={referable}
                    eyeSide={eye.eyeSide}
                    onClick={onClickEyeSideButton}
                  />
                );
              else
                return (
                  <EyeSideButton
                    key={index}
                    index={index}
                    isActive={false}
                    status={eye.status}
                    referable={eye.referable}
                    eyeSide={eye.eyeSide}
                    onClick={onClickEyeSideButton}
                  />
                );
            })}
          </div>
          {images.length >= 6 && (
            <span className="arrows left" onClick={() => handleNav("left")}>
              <ArrowBackIosIcon />
            </span>
          )}
          <div className="box_list_thumbnails" ref={navRef}>
            {images &&
              images.map((image, index) => (
                <ThumbnailImage
                  isActive={index === imageIndex}
                  image={image}
                  key={index}
                  index={index}
                  onClick={onClickThumbnailImage}
                />
              ))}
          </div>
          {images.length >= 6 && (
            <span className="arrows right" onClick={() => handleNav("right")}>
              <ArrowForwardIosIcon />
            </span>
          )}
        </Box>

        <div className="box_view_thumbnails">
          <div className="box_view_detail">
            <SubSigns
              eyeIndex={eyeIndex}
              status={status}
              displaySigns={displaySigns}
              checkDataSigns={checkDataSigns}
            />

            {currentImage && (
              <ImageMagnifier image={currentImage} branch={branch} />
            )}
          </div>
          <Diseases displayDiseases={displayDiseases} />
          <Divider />
          <fieldset className="fieldset_form_detail">
            <legend>Comentarios adicionales</legend>
            <div className="box_form_detail">
              <TextField
                id="filled-textarea"
                disabled={status === "evaluated"}
                multiline
                placeholder="Opcional"
                value={comment}
                variant="filled"
                rows={3}
                onChange={(event) => onChangeComment(event.target.value)}
              />
            </div>
          </fieldset>
        </div>

        {eyes && eyes.length > 0 && (
          <EvaluationStep
            eyes={eyes}
            eyeIndex={eyeIndex}
            onClickEvaluation={onClickEvaluation}
            setShowImage={setShowImage}
            isLoader={isLoader}
          />
        )}
      </div>
    </>
  ) : null;
};

export default memo(PatientEvaluation);

//Built-in
import React, { useContext, memo } from "react";

/**
 * mui
 */
import { Grid, Box } from "@mui/material";

/**
 * components
 */
import Buttons from "src/components/core/button";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

/**
 * external
 */
import { Oval } from "react-loader-spinner";

const EvaluationStep = ({
  eyes,
  eyeIndex,
  onClickEvaluation,
  setShowImage,
  isLoader,
}) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor } = channelParameters;
  const secondary = secondaryColor || primaryColor;

  const back = () => setShowImage(false);

  const hasStatus = (status) => {
    const find = eyes.filter((eye) => eye.status === status);
    return find.length > 0;
  };

  const finish = () => {
    if (eyes.length === 1) return true;
    if (!hasStatus("evaluated")) return false;
    const currentEye = eyes[eyeIndex];
    const { status } = currentEye;
    if (status === "pending") return true;
    return false;
  };

  const isFinish = finish();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className="mg t5"
      style={{ justifyContent: "space-between" }}
    >
      <Grid className="item-content" item xs={12} sm={2}>
        <div className="button-component" onClick={back}>
          <Buttons title="Regresar" type="button" colors={secondary} />
        </div>
      </Grid>
      {!hasStatus("pending") ? null : (
        <Grid item className="item-content" xs={12} sm={2}>
          {isLoader ? (
            <Oval
              color={secondary}
              wrapperStyle={{ justifyContent: "center" }}
              height={30}
              width={30}
            />
          ) : (
            <Box
              className="button-component"
              onClick={(event) => onClickEvaluation(event, isFinish)}
            >
              <Buttons
                title={isFinish ? "Guardar" : "Siguiente"}
                type="button"
                colors={secondary}
              />
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default memo(EvaluationStep);

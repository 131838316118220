//Built-in
import { useState, useEffect } from "react";
import { iEye } from "src/core/models/Eye";

interface iUseEyes {
  data: iEye[] | null;
}

const useEyes = ({ data }: iUseEyes) => {
  const [eyes, setEyes] = useState<iEye[] | null>(null);

  const [eyeIndex, setEyeIndex] = useState<number>(0);

  const [currentEye, setCurrentEye] = useState<iEye | null>(null);

  useEffect(() => {
    setEyes(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(eyes)) setCurrentEye(eyes[eyeIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eyeIndex, JSON.parse(JSON.stringify(eyes))]);

  return { eyes, eyeIndex, setEyes, setEyeIndex, currentEye };
};

export default useEyes;

//Built-in
import React, { memo, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

const Diseases = ({ displayDiseases }) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor } = channelParameters;

  return (
    <Box my={2}>
      <h3 style={{ textTransform: "uppercase" }}>Padecimientos</h3>
      {displayDiseases && (
        <Grid container style={{ marginBottom: 16 }} spacing={1}>
          {displayDiseases &&
            displayDiseases.map((data, index) => {
              const { label, value } = data;
              const { color } = data;
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <h4 style={{ margin: 0, color: color }}> {label}</h4>
                  <Box display="flex">
                    <span style={{ color: primaryColor, fontWeight: 600 }}>
                      {value}
                    </span>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      )}
    </Box>
  );
};

export default memo(Diseases);

//Built-in
import React, { CSSProperties } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface iCard {
  title?: string;
  children?: React.ReactNode;
  titleStyle?: CSSProperties;
  // All other props
  [x: string]: any;
}

const CustomCard = ({ title, children, titleStyle, ...rest }: iCard) => {
  const useStyles = makeStyles(() => ({
    cardTitle: {
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();

  return (
    <Card elevation={4} {...rest}>
      <CardContent>
        {title && (
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="body2"
              style={titleStyle}
              className={classes.cardTitle}
            >
              {title}
            </Typography>
          </Box>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

export default CustomCard;

import {
  iDiseasesInfo,
  iData,
  iConcatSigns,
  iReport,
  iDiseasesCode,
} from "./DiseasesData";

export abstract class Diseases {
  public buildDisplayDiseases = (key: string, valueKey: string): iData => {
    const diseasesCode = this.buildDiseasesCode();
    const diseasesInfo = diseasesCode[key] as iDiseasesInfo;
    const { name, classification, catalogue } = diseasesInfo;
    const value = classification[valueKey];
    let text = '';
    let id = '';
    if (valueKey !== "0") {
      text = catalogue[valueKey];
      id = `${key}-${valueKey}`
    }
    return { label: name, value: value, catalogue: text, catalogueId: id};
  };

  abstract buildDiseasesCode(): iDiseasesCode;

  abstract buildDiseases(signs: iConcatSigns): iReport;
}

import { Diseases } from "../models/Diseases";
import { DiseasesAdult } from "../models/DiseasesAdult";
import { iConcatSigns, iData, iReport } from "../models/DiseasesData";
import { DiseasesRop } from "../models/DiseasesRop";
import { iDataSigns, iSigns } from "../models/Eye";
import { iSubSigns } from "../models/SignsData";

const buildConcatSigns = (selectedSigns: iSigns): iConcatSigns => {
  let selectedDiseases: iConcatSigns = {};
  for (const [key, value] of Object.entries(selectedSigns)) {
    const data = value as iDataSigns;
    for (const [dataKey, dataValue] of Object.entries(data)) {
      let concatKey: string;
      if (key) concatKey = key + "-" + dataKey;
      else concatKey = dataKey;
      selectedDiseases = {
        ...selectedDiseases,
        [concatKey]: dataValue,
      };
    }
  }
  return selectedDiseases;
};

export const buildDiseases = (
  selectedSigns: iSigns,
  isAdult: boolean
): iReport => {
  const signs = buildConcatSigns(selectedSigns);
  const disease: Diseases = isAdult ? new DiseasesAdult() : new DiseasesRop();
  return disease.buildDiseases(signs);
};

export const mapCatalogDiseases = (diseases: iData[] | null): string[] => {
  if (!diseases || diseases.length === 0) return [];
  const catalogue = diseases.map((value) => value.catalogue);
  return catalogue;
};

export const buildSigns = (display: iSubSigns[]): iSubSigns => {
  let signs: iSubSigns = {};
  display.forEach((data: iSubSigns) => {
    for (const [key, value] of Object.entries(data)) {
      signs = {
        ...signs,
        [key]: value,
      };
    }
  });
  return signs;
};

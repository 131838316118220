/**
 * mui
 */
import { red } from "@mui/material/colors";

/**
 * models
 */
import { Diseases } from "./Diseases";
import { iData, iConcatSigns, iReport, iDiseasesCode } from "./DiseasesData";

export class DiseasesRop extends Diseases {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  buildDiseases = (signs: iConcatSigns): iReport => {
    let diseases: iData[] = [];
    let referable = false;
    //ROP//
    const valueROP = this.ROP_level(signs);
    if (valueROP !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-ROP", valueROP));
    //PLUS
    const valueLPLUS = this.PLUS_level(signs);
    if (!referable && valueLPLUS !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-PLUS", valueLPLUS));
    //AROP
    const valueAROP = this.AROP_level(signs);
    if (!referable && valueAROP !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-AROP", valueAROP));
    //DIM
    const valueDIM = this.DIM_level(signs);
    if (!referable && valueDIM !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-IM", valueDIM));
    //D-OR
    const valueOR = this.OR_presence(signs);
    if (!referable && valueOR !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-OR", valueOR));
    //D-OG
    const valueOG = this.OG_presence(signs);
    if (!referable && valueOG !== "0") referable = true;
    diseases.push(this.buildDisplayDiseases("D-OG", valueOG));

    let data = diseases.filter(
      (data) => data.value !== "Sin" && data.value !== "No"
    );
    data = data.length > 0 ? data : [{ label: "Sin", color: red[500], catalogueId: '' }];
    return {
      data: data,
      referable: referable,
    };
  };

  buildDiseasesCode = (): iDiseasesCode => {
    const diseasesCode: iDiseasesCode = {
      "D-ROP": {
        name: "Retinopatia del Prematuro",
        classification: {
          "0": "Sin",
          "1": "Grado 1",
          "2": "Grado 2",
          "3": "Grado 3",
          "4": "Grado 4 o Grado 5 (desprendimiento)",
        },
        catalogue: {
          "1": "Retinopatía del prematuro grado 1",
          "2": "Retinopatía del prematuro grado 2",
          "3": "Retinopatía del prematuro grado 3",
          "4": "Retinopatía del prematuro grado 4 o grado 5 (desprendimiento)",
        },
      },

      "D-PLUS": {
        name: "Enfermedad PLUS",
        classification: { "0": "Sin", "1": "Preplus", "2": "Plus" },
        catalogue: {
          "1": "Preplus",
          "2": "Enfermedad Plus",
        },
      },

      "D-AROP": {
        name: "ROP Agresivo",
        classification: {
          "0": "No",
          "1": "Si",
        },
        catalogue: {
          "1": "ROP Agresivo",
        },
      },

      "D-IM": {
        name: "Retina inmadura",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Retina inmadura",
        },
      },

      "D-OR": {
        name: "Otra retina",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Otra Retina",
        },
      },

      "D-OG": {
        name: "Otra general",
        classification: { "0": "No", "1": "Si" },
        catalogue: {
          "1": "Otra General",
        },
      },
    };

    return diseasesCode;
  };

  ROP_level = (signs: iConcatSigns): string => {
    if (signs["S-ROP-4"]) return "4";

    if (signs["S-ROP-3"]) return "3";

    if (signs["S-ROP-2"]) return "2";

    if (signs["S-ROP-1"]) return "1";

    return "0";
  };

  PLUS_level = (signs: iConcatSigns): string => {
    if (signs["S-VA-9b"]) return "2";

    if (signs["S-VA-9a"] && signs["S-VA-8"]) return "1";

    return "0";
  };

  AROP_level = (signs: iConcatSigns): string => {
    if (
      (signs["S-VA-X"] && (signs["S-VA-9a"] || signs["S-VA-8"])) ||
      signs["S-VA-9b"]
    )
      return "1";

    return "0";
  };

  DIM_level = (signs: iConcatSigns): string => {
    if (signs["S-ROP-0"]) return "1";

    return "0";
  };

  OR_presence = (signs: iConcatSigns): string => {
    if (
      signs["S-HM-2"] ||
      signs["S-HM-3"] ||
      signs["S-HM-4"] ||
      signs["S-HM-5"] ||
      signs["S-HM-6"]
    )
      return "1";

    if (signs["S-OT-XR"] || signs["S-EX-1"]) return "1";

    return "0";
  };

  OG_presence = (signs: iConcatSigns): string => {
    if (signs["S-OT-XG"] || signs["S-OT-16"]) return "1";

    return "0";
  };
}

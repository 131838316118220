import { useState, useEffect, useCallback } from "react";

/**
 * models
 */
import { iEye, iSigns, iDataSigns } from "src/core/models/Eye";
import { iDisplaySigns, iCheckDataSigns } from "src/core/models/SignsData";
import { Branch } from "src/core/models/Patient";
import { Signs } from "src/core/models/Signs";
import { SignsAdult } from "src/core/models/SignsAdult";
import { SignsRop } from "src/core/models/SignsRop";

interface iUseImages {
  currentEye: iEye | null;
  branch: Branch | undefined;
}

const useSigns = ({ currentEye, branch }: iUseImages) => {
  const [selectedSigns, setSelectedSigns] = useState<iSigns | null>(null);
  const [displaySigns, setDisplaySigns] = useState<iDisplaySigns[] | null>(
    null
  );

  const isAdult = !branch || branch === "retinia-adult";

  useEffect(() => {
    if (currentEye) {
      const { selectedSubSigns } = currentEye;
      setDisplaySigns(fillDisplaySigns(selectedSubSigns as iSigns));
      setSelectedSigns(JSON.parse(JSON.stringify(selectedSubSigns)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEye]);

  // fill display signs by selected signs
  const fillDisplaySigns = (selected: iSigns): iDisplaySigns[] => {
    const signs: Signs = isAdult ? new SignsAdult() : new SignsRop();
    const displaySigns: iDisplaySigns[] = signs.buildDisplaySigns();
    displaySigns.forEach((display) => {
      const { code, subSigns } = display;
      const findSelected: iDataSigns = selected[code];
      if (findSelected) {
        for (let key in subSigns) {
          if (findSelected[key]) subSigns[key].value = findSelected[key];
        }
      }
    });
    return JSON.parse(JSON.stringify(displaySigns));
  };

  const checkDataSigns = useCallback(
    (check: iCheckDataSigns): void => {
      const { codeSigns, codeData, value } = check;
      const selected: iSigns = selectedSigns as iSigns;
      const findSelected: iDataSigns = selected[codeSigns];
      if (findSelected) findSelected[codeData] = value;
      setSelectedSigns(JSON.parse(JSON.stringify(selected)));
      setDisplaySigns(fillDisplaySigns(selected));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedSigns, setDisplaySigns, selectedSigns]
  );

  return { selectedSigns, displaySigns, checkDataSigns };
};

export default useSigns;

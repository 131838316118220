//Built-in
import React, { useEffect, useState, useContext, memo } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

//Components
import CheckSigns from "../checkSigns/checkSigns";

//Styles
import "./subSigns.scss";


const SubSigns = ({
  displaySigns,
  status,
  eyeIndex,
  checkDataSigns,
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: "#f7f7f7",
      },
      details: {
        "&>div:last-child": {
          paddingBottom: 10,
        },
      },
      heading: {
        fontSize: theme.typography.pxToRem(13.5),
        fontWeight: 500,
      },
    })
  );

  const classes = useStyles();

  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor } = channelParameters;
  const secondary = secondaryColor || primaryColor;

  const [actualSign, setActualSign] = useState(-1);

  useEffect(() => {
    setActualSign(-1);
  }, [eyeIndex]);

  const changeData = (checkData) => {
    checkDataSigns(checkData);
  };

  const checked = (subSigns) => {
    let check = false;
    for (let key in subSigns) {
      const data = subSigns[key];
      const { value } = data;
      if (value) {
        check = value;
        break;
      }
    }
    return check;
  };

  return (
    <div className="wrapper_tabs">
      <AppBar position="static" style={{ backgroundColor: "#fff" }}>
        <Tabs
          centered
          value={0}
          aria-label="simple tabs example"
          indicatorColor="secondary"
          selectionFollowsFocus
        >
          <Tab style={{ color: "rgba(0, 0, 0, 0.87)" }} label="hallazgos" />
        </Tabs>
      </AppBar>
      <Box mt={1}>
        <Typography component="span">
          {
            <div className={classes.root}>
              {displaySigns &&
                displaySigns.map((data, i) => {
                  const { subSigns, label, code, display } = data;
                  const check = checked(subSigns);
                  return (
                    <Accordion
                      key={i}
                      elevation={3}
                      expanded={actualSign === i ? true : false}
                    >
                      <AccordionSummary
                        expandIcon={
                          <VisibilityOutlinedIcon
                            style={{
                              color: check ? secondary : "#b7b7b7",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          setActualSign(actualSign === i ? -1 : i);
                        }}
                      >
                        <Typography className={classes.heading}>
                          {label}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={classes.details}
                        style={{
                          backgroundColor: "#f7f7f7",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {display.map((data) => {
                          return Object.entries(data).map(
                            ([key, data], index) => {
                              const { label, value } = data;
                              return (
                                <CheckSigns
                                  disabled={status === "evaluated"}
                                  key={index}
                                  value={value}
                                  label={label}
                                  changeData={(data) =>
                                    changeData({
                                      codeSigns: code,
                                      codeData: key,
                                      value: data,
                                    })
                                  }
                                />
                              );
                            }
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          }
        </Typography>
      </Box>
    </div>
  );
};

export default memo(SubSigns);

//Built-in
import React, { memo, useContext } from "react";

/**
 * mui
 */
import { Box, Autocomplete, TextField, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

/**
 * context
 */
/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";
import { PatientFollowContext } from "src/context/PatientFollowContext";

/**
 * external
 */
import moment from "moment";


const h4Style = {
  display: "flex",
  alignItems: "center",
};

const PatientInfo = ({ pacientData, color, branch }) => {
  const { name, last_name, enhancedAge, date, referable, time, status } =
    pacientData;


  const { catalogues, data, setData } = useContext(PatientFollowContext);
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor } = channelParameters;

  const isAdult = !branch || branch === "retinia-adult";

  const isEvaluated = status === "evaluated";

  const divStyle = {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: referable === "referable" ? "red" : "green",
    marginLeft: 8,
  };

  return (
    <Box className="aside_info_user">
      <Box className="name_info_user">
        <span>
          {!isEvaluated ? (
            <RemoveCircleOutlineIcon />
          ) : (
            <CheckCircleIcon style={{ color: color }} />
          )}
        </span>
        <Box sx={{ width: "100%" }}>
          <h3 className="mg m0">
            {name} {last_name}
          </h3>
          <p>
            {enhancedAge} {time}
          </p>
          <p>{moment(date).format("DD-MM-YYYY HH:mm")}</p>
          <h4 style={h4Style}>
            {referable === "referable" ? "Referible" : "No referible"}
            <Box style={divStyle}></Box>
          </h4>
          {!isAdult && (
            <Box>
              <Box component="h5" sx={{ color: primaryColor }}>
                {"Seguimiento"}
              </Box>
              <Autocomplete
                id="follow"
                value={data}
                onChange={(event, newValue) => {
                  setData(newValue);
                }}
                disabled={isEvaluated}
                autoHighlight
                options={catalogues}
                getOptionLabel={(option) => option.text}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props} key={option.id}>
                      <Typography variant="body2">{option.text}</Typography>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Opciones"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: "14px" },
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default memo(PatientInfo);

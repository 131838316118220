//Built-in
import { useState, useEffect } from "react";
import { EyeSide, iEye, iImage, iSigns } from "src/core/models/Eye";
import { iEvaluations, iEvaluationSigns } from "src/core/models/Evaluations";

/**
 * internal
 */
import { iClinicalInfo } from "src/core/models/ClinicalInfo";
import { SignsRop } from "src/core/models/SignsRop";
import { Signs } from "src/core/models/Signs";
import { Branch } from "src/core/models/Patient";
import { SignsAdult } from "src/core/models/SignsAdult";
import { buildDiseases, mapCatalogDiseases } from "src/core/util/util";

/**
 * external
 */
import axios from "axios";

interface iUseInteraction {
  interaction_id: string;
  doctor_id: number;
  branch: Branch | undefined;
}

const useInteraction = ({
  interaction_id,
  doctor_id,
  branch,
}: iUseInteraction) => {
  const token: string = localStorage.getItem("token") as string;

  const [eyes, setEyes] = useState<iEye[] | null>(null);

  const [clinicalInfo, setClinicalInfo] = useState<iClinicalInfo | null>(null);

  const [loading, setLoading] = useState(true);

  const isAdult = !branch || branch === "retinia-adult";

  // building eyes
  const buildEye = (
    eyeSide: EyeSide,
    evaluations: iEvaluations[],
    evaluationSigns: iEvaluationSigns[]
  ): iEye | null => {
    const evaluationsSide = evaluations.filter(
      (evaluation) => evaluation.ev_image_eye_side === eyeSide
    );
    if (!evaluationsSide || evaluationsSide.length === 0) return null;
    const images = buildImages(evaluationsSide);
    let eye: iEye;
    const signs = getEvaluationSigns(eyeSide, evaluationSigns);
    const initSigns: Signs = isAdult ? new SignsAdult() : new SignsRop();
    if (signs) {
      const data: iEvaluationSigns = signs as iEvaluationSigns;
      const { evaluation, dataSubSigns } = data;
      const merge = mergeSigns(dataSubSigns, initSigns.buildInitSigns());
      const build = buildDiseases(merge, isAdult);
      eye = {
        comment: evaluation.comment,
        evaluation: true,
        eyeSide: eyeSide,
        images: images,
        status: "evaluated",
        referable: evaluation.referable,
        selectedSubSigns: merge,
        diseases: mapCatalogDiseases(build.data),
      };
    } else {
      eye = {
        comment: "",
        evaluation: false,
        eyeSide: eyeSide,
        images: images,
        status: "pending",
        referable: null,
        selectedSubSigns: initSigns.buildInitSigns(),
        diseases: [],
      };
    }

    return eye;
  };

  // building image
  const buildImages = (evaluations: iEvaluations[]): iImage[] => {
    const images: iImage[] = [];
    evaluations.forEach((evaluation) => {
      const image: iImage = {
        id: evaluation.image_id,
        url: evaluation.image_url,
        preprocessedUrl: evaluation.ev_image_preprocessed_url,
        quality: evaluation.ev_image_quality_num,
      };
      images.push(image);
    });
    return images;
  };

  const mergeSigns = (data: iSigns, initSigns: iSigns): iSigns => {
    let init = { ...initSigns };
    for (let key in data) {
      if (init[key]) {
        for (let subKey in data[key]) {
          if (key === "S-DA" && subKey === "5")
            init[key]["X"] = data[key][subKey];
          else if (key === "S-OT") {
            if (subKey === "14") init[key]["XR"] = data[key][subKey];
            else if (subKey === "15") init[key]["XG"] = data[key][subKey];
            else if (subKey === "13") init["S-NP"]["4"] = data[key][subKey];
            else if (subKey === "3") init["S-NP"]["1"] = data[key][subKey];
            else init[key][subKey] = data[key][subKey];
          } else init[key][subKey] = data[key][subKey];
        }
      }
    }
    return init;
  };

  // has evaluations by signs
  const getEvaluationSigns = (
    eyeSide: EyeSide,
    evaluationSigns: iEvaluationSigns[]
  ): iEvaluationSigns | null => {
    if (!evaluationSigns || evaluationSigns.length === 0) return null;
    const evaluation = evaluationSigns.filter(
      (evaluation) => evaluation.patient.eyeSide === eyeSide
    );
    return evaluation.length > 0 ? evaluation[0] : null;
  };

  useEffect(() => {
    const getEvaluations = async () => {
      const urlEvaluations = `${process.env.REACT_APP_API_GET_EVALUATIONS}?interaction_id=${interaction_id}`;
      const evaluations = axios.get(urlEvaluations);

      const urlEvaluationSigns = `${process.env.REACT_APP_API_GET_SIGNS_QUERY}?interaction_id=${interaction_id}&doctor_id=${doctor_id}`;
      const evaluationSigns = axios.get(urlEvaluationSigns);

      await axios
        .all([evaluations, evaluationSigns])
        .then(
          axios.spread((...response) => {
            let eyes: iEye[] = [];
            const evaluations: iEvaluations[] = response[0]["data"]["output"] as iEvaluations[];
            const evaluationSigns: iEvaluationSigns[] = [...response[1]["data"]["output"],] as iEvaluationSigns[];
            const eyeLeft = buildEye("left eye", evaluations, evaluationSigns);
            if (eyeLeft) eyes.push(eyeLeft);
            const eyeRigth = buildEye(
              "right eye",
              evaluations,
              evaluationSigns
            );
            if (eyeRigth) eyes.push(eyeRigth);
            setEyes(eyes);
            setLoading(false);
          })
        )
        .catch((err) => {
          console.log(err);
        });
    };
    const getClinicalInfo = async () => {
      const url = `${process.env.REACT_APP_API_GET_CLINICAL_INFO}?interaction_id=${interaction_id}`;
      await axios
        .get(url)
        .then((response: any) => {
          setClinicalInfo(response["data"]["output"] as iClinicalInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getEvaluations();
    getClinicalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, interaction_id]);

  return { eyes, loading, clinicalInfo, setEyes };
};

export default useInteraction;

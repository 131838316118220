/**
 *  mui
 */
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

/**
 *  context
 */

import { ChannelParametersProvider } from "@prosperia/context.channel-parameters-context";

/**
 *  components
 */
import { Layouts } from "../layouts/Layouts";

/**
 *  hook
 */
import useTheme from "../hook/useTheme";
import useChannelParameters from "../hook/common/useChannelParameters";

const Theme = () => {
  useChannelParameters();
  const { lightTheme } = useTheme();
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Layouts />
    </ThemeProvider>
  );
};

const Main = () => {
  return (
    <ChannelParametersProvider>
      <Theme />
    </ChannelParametersProvider>
  );
};

export default Main;

//Built-in
import React, { useEffect, useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  checkbox: {
    padding: "1px",
    marginLeft: 9,
    marginRight: 9,
  },
  label: {
    fontSize: theme.typography.pxToRem(11.8),
    fontWeight: 500,
  },
  formControl: {
    margin: "8px 16px 0px 16px",
  },
}));

interface iCheckSigns {
  value: boolean;
  label: string;
  disabled: boolean;
  changeData: (value: boolean) => void;
}

const CheckSigns = ({ value, label, disabled, changeData }: iCheckSigns) => {
  const classes = useStyles();

  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    setState(value);
  }, [value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setState(value);
    changeData(value);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                disabled={disabled}
                className={classes.checkbox}
                checked={state}
                onChange={onChange}
                name={label}
                color="secondary"
              />
            }
            label={<Typography className={classes.label}>{label}</Typography>}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};
export default memo(CheckSigns);

//Built-in
import React, { CSSProperties, Fragment, useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";

//Components
import CustomCard from "src/components/core/customCard";
import InformationCard from "src/components/core/informationCard";

//Models
import {
  iClinicalInfo,
  iEye,
  iData,
  iCard,
} from "src/core/models/ClinicalInfo";

const liStyle: CSSProperties = {
  fontSize: 13,
  fontWeight: 500,
};

const titleStyle: CSSProperties = {
  fontSize: 15,
  fontWeight: "bold",
};

const cardStyle: CSSProperties = {
  height: "100%",
};

interface iEyeSideGrid {
  eye: iEye;
}

const EyeSideGrid = ({ eye }: iEyeSideGrid) => {
  const theme: Theme = useTheme();
  const { title, color, information } = eye;
  const titleColor: CSSProperties = {
    color: color
      ? color === "primary"
        ? theme.palette.primary.main
        : red[500]
      : "",
  };
  return (
    <Grid item xs={12} md={6}>
      <CustomCard
        title={title}
        style={cardStyle}
        titleStyle={{ ...titleStyle, ...titleColor }}
      >
        <Grid container spacing={2} style={{ marginTop: 5 }}>
          {information &&
            information.map((value, i) => {
              return <DataCard key={i} information={value} />;
            })}
        </Grid>
      </CustomCard>
    </Grid>
  );
};

interface iDataCard {
  information: iData;
}

const DataCard = ({ information }: iDataCard) => {
  const theme: Theme = useTheme();
  const { title, color, data } = information;

  const getColor = (color: "primary" | "red" | undefined): CSSProperties => {
    return {
      color: color
        ? color === "primary"
          ? theme.palette.primary.main
          : red[500]
        : "",
    };
  };

  const titleColor = getColor(color);

  const style = {
    ...titleStyle,
    fontSize: 13,
  };

  const liStyle2: CSSProperties = {
    ...liStyle,
    fontSize: 12.5,
  };

  return (
    <Grid item xs={11}>
      <CustomCard
        title={title}
        style={cardStyle}
        titleStyle={{ ...style, ...titleColor }}
      >
        <InformationCard data={data as string[]} liStyleProps={liStyle2} />
      </CustomCard>
    </Grid>
  );
};

interface iSection {
  cards: iCard[];
}

interface iPatientClinicalInfo {
  clinicalInfo: iClinicalInfo;
}

const PatientClinicalInfo = ({ clinicalInfo }: iPatientClinicalInfo) => {
  const theme: Theme = useTheme();
  const [sections, setSections] = useState<iSection[]>([]);

  const getCardSection = (cards: iCard[]): iSection[] => {
    let sections: iSection[] = [];

    const sectionOne = filterCardSection(
      cards,
      (card: iCard) =>
        card.type === "CRD-EYE-REC-1" || card.type === "CRD-EYE-REF-NOT-IMG-1"
    );
    if (sectionOne) sections.push(sectionOne);

    const sectionTwo = filterCardSection(
      cards,
      (card: iCard) => card.type === "CRD-EYE-IMG-1"
    );
    if (sectionTwo) sections.push(sectionTwo);

    const sectionThird = filterCardSection(
      cards,
      (card: iCard) =>
        card.type === "CRD-EYE-INF-1" || card.type === "CRD-EYE-REA-1"
    );
    if (sectionThird) sections.push(sectionThird);

    return sections;
  };

  const filterCardSection = (cards: iCard[], lambda: any): iSection | null => {
    if (!cards) return null;
    const data = cards.filter((card) => lambda(card));
    if (data.length === 0) return null;
    return { cards: data };
  };

  const { cards } = clinicalInfo;

  useEffect(() => {
    if (cards) {
      const sections: iSection[] = getCardSection(cards);
      setSections(sections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return sections && sections.length > 0 ? (
    <>
      {sections.map((section, i) => {
        const { cards } = section;
        return (
          <Grid key={i} container alignItems="stretch" spacing={2}>
            {cards &&
              cards.length > 0 &&
              cards.map((card, j) => {
                const { type, color, title, data, left, right } = card;
                const titleColor: CSSProperties = {
                  color: color
                    ? color === "primary"
                      ? theme.palette.primary.main
                      : red[500]
                    : "",
                };
                switch (type) {
                  case "CRD-EYE-IMG-1":
                    return (
                      <Fragment key={j}>
                        {left && <EyeSideGrid eye={left} />}
                        {right && <EyeSideGrid eye={right} />}
                      </Fragment>
                    );
                  default:
                    return (
                      <Grid key={j} item xs={12} md={6}>
                        <CustomCard
                          title={title}
                          style={cardStyle}
                          titleStyle={{ ...titleStyle, ...titleColor }}
                        >
                          <InformationCard
                            data={data as string[]}
                            liStyleProps={liStyle}
                          />
                        </CustomCard>
                      </Grid>
                    );
                }
              })}
          </Grid>
        );
      })}
    </>
  ) : null;
};

export default PatientClinicalInfo;

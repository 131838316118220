import React, { createContext, useState, useEffect } from "react";

/**
 * external
 */
import axios from "axios";

/**
 * models
 */
import { Catalogue } from "src/core/models/Catalogue";

export interface iPatientFollowContext {
  catalogues: Catalogue[];
  data: Catalogue | null;
  setData: React.Dispatch<React.SetStateAction<Catalogue | null>>;
}

export const PatientFollowContext = createContext<iPatientFollowContext | null>(
  null
);

interface iPatientFollowProvider {
  value: Catalogue | null;
  children: React.ReactNode;
}

const PatientFollowProvider = ({ value, children }: iPatientFollowProvider) => {
  const [data, setData] = useState<Catalogue | null>(null);
  const [catalogues, setCatalogues] = useState<Catalogue[]>([]);

  useEffect(() => {
    setData(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const getFollow = async () => {
      const url = `${process.env.REACT_APP_API_GET_CATALOGUE}?name=follow_up`;
      await axios
        .get(url)
        .then((res) => {
          const result = res.data;
          const { data } = result;
          setCatalogues(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getFollow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientFollowContext.Provider
      value={{
        catalogues,
        data,
        setData,
      }}
    >
      {children}
    </PatientFollowContext.Provider>
  );
};

export default PatientFollowProvider;

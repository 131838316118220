//Built-in
import React, { CSSProperties } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface iInformationCard {
  title?: string;
  data?: string[] | string;
  titleStyleProps?: CSSProperties;
  ulStyleProps?: CSSProperties;
  liStyleProps?: CSSProperties;
}
const InformationCard = ({
  title,
  data,
  titleStyleProps,
  ulStyleProps,
  liStyleProps,
}: iInformationCard) => {
  const useStyles = makeStyles(() => ({
    titleContainer: {
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
    ulContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
  }));

  const titleStyle: CSSProperties = {
    fontSize: 15,
    paddingLeft: 20,
  };

  const liStyle: CSSProperties = {
    fontSize: 14.4,
  };

  const ulStyle: CSSProperties = {
    paddingLeft: !data || Array.isArray(data) ? 20 : 0,
    listStyleType: !data || Array.isArray(data) ? "initial" : "none",
  };

  const classes = useStyles();

  return (
    <>
      {title && (
        <Typography
          variant="body2"
          style={titleStyleProps ? titleStyleProps : titleStyle}
          className={classes.titleContainer}
        >
          {title}
        </Typography>
      )}
      <ul
        style={ulStyleProps ? ulStyleProps : ulStyle}
        className={classes.ulContainer}
      >
        {!data ? null : !Array.isArray(data) ? (
          <li style={liStyleProps ? liStyleProps : liStyle}>{data}</li>
        ) : (
          data.map((value, i) => {
            return (
              <li key={i} style={liStyleProps ? liStyleProps : liStyle}>
                {value}
              </li>
            );
          })
        )}
      </ul>
    </>
  );
};

export default InformationCard;

import { useContext } from "react";

/**
 *  mui
 */
import { createTheme } from "@mui/material/styles";

/**
 *  context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

const useTheme = () => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor } = channelParameters;
  
  const primary = primaryColor  || "#CFCFCF";
  const secondary = secondaryColor || primaryColor || "#CFCFCF";


  const lightTheme = createTheme({
    downSm: {
      navBar: "48px",
      backDrop: "53px",
      gapMain: "85px",
    },
    upSm: {
      navBar: "64px",
      backDrop: "58px",
      gapMain: "102px",
    },
    custom: {
      color: "#FFFFFF",
      minWidth: "min-width:1440px",
      maxWidth: "max-width:319px",
      maxHeight: "max-height:709px",
    },
    palette: {
      // mode theme
      mode: "light",
      //primary color
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      background: {
        default: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
  });

  const darkTheme = createTheme({
    downSm: {
      navBar: "48px",
      backDrop: "53px",
      gapMain: "85px",
    },
    upSm: {
      navBar: "64px",
      backDrop: "58px",
      gapMain: "102px",
    },
    custom: {
      color: "rgba(0, 0, 0, 1)",
    },
    palette: {
      // mode theme
      mode: "dark",
      //primary color
      primary: {
        main: primary,
        //color inside component like datepicker
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: secondary,
      },
      background: {
        paper: "rgba(0, 0, 0, 1)",
      },
    },
    typography: {
      fontFamily: "Raleway",
    },
  });
  return {
    lightTheme,
    darkTheme,
  };
};

export default useTheme;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/theme/main.scss";
import "./core/interceptor/interceptor";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

// Sentry.init({
//   dsn: "https://fd151760b6f9429ba2dc167d1ac60922@o912561.ingest.sentry.io/5849706"
// });

ReactDOM.render(
  <BrowserRouter>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </Sentry.ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//Built-in
import React from "react";

//Styles
import "./footer.scss";

export const Footer = (props) => {
  const styles = {
    background: props.primaryColor,
  };

  return (
    <footer className="wrapper_footer" style={styles}>
      <div className="cont_img">
        <img
          src={
            "https://calcs-app.s3.amazonaws.com/resources/powered_by_prosperia.png"
          }
          alt="brand"
        />
      </div>
    </footer>
  );
};

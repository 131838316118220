import React, { useContext } from "react";

/**
 * mui
 */
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

export const Search = ({ search, onChange, onKeyDown, onClean }) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor } = channelParameters;

  return (
    <FormControl fullWidth sx={{ m: 1 }} color="primary">
      <InputLabel
        htmlFor="outlined-search"
        sx={{ color: primaryColor, fontWeight: 600 }}
      >
        Buscar
      </InputLabel>
      <OutlinedInput
        id="outlined-search"
        value={search ? search : ""}
        onChange={onChange}
        onKeyDown={onKeyDown}
        sx={{
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
            borderColor: primaryColor,
            borderWidth: "2px",
          },
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton aria-label="search">
              <SearchIcon color="primary" />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={onClean} aria-label="search">
              {search && <CloseIcon />}
            </IconButton>
          </InputAdornment>
        }
        label="Buscar"
      />
    </FormControl>
  );
};
